import React, { useState, useEffect, useRef, } from "react";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import Navigation from "../../Navigation";
import {
  AppLayout,
  Box,
  Header,
  HelpPanel,
  Button,
  Form,
  Container,
  Modal,
  Textarea,
  Grid,
  Link,
  SpaceBetween,
  Cards,
  ContentLayout,
  ColumnLayout,
} from "@cloudscape-design/components";
import '../locales/index';
import { useTranslation } from 'react-i18next';
import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';

import { Amplify } from 'aws-amplify'
import { get } from 'aws-amplify/api';

import { useNavigate } from "react-router-dom";
//
import peace from "./peace.png";
import hope from "./hope.png";
import people from "./pi.png";
import pray from "./pray.png";
//
import whoami from "./whoami.png";
import illness from "./illness.png";
import anxiety from "./anxiety.png";
import forgiveness from "./forgiveness.png";
import outputs from '../../aws-exports.js';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(outputs);
var myInit;
const apiName = "matt1820api";
const path = "/dev";

const Content = (lang) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang['lang']);

  }, [i18n]);

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [learnmorelabel, setLearnmorelabel] = useState("");
  const [linkDisabled, setLinkDisabled] = useState(true);

  const [verseContent, setVerseContent] = useState(" . . . ");
  const [bookName, setBookName] = useState("");
  const [verseStart, setVerseStart] = useState("");
  const [hyphen, setHyphen] = useState("");
  const [verseEnd, setVerseEnd] = useState("");
  const [aggDaily, setAggDaily] = useState("");
  const [selectValue, setSelectValue] = useState({
    label: "Forgive",
    value: "1",
  });
  const [modalVisible, setModalVisible] = useState(false);
  //console.log("Content called " + JSON.stringify(selectValue));


  const navigateHome = (event) => {
    console.log("navigateHome " + JSON.stringify(event))
    navigate("/Home");
  };

  const aggDailyVerse = (book, verseStart, verseEnd) => {
    var sArr = book.split(/(\s+)/);
    var chapter = sArr[4].toString();

    sArr = verseStart.split(":");
    var start = sArr[0];

    if (start[0] === "0") {
      start = start.substring(1);
    }

    sArr = verseStart.split(":");
    var end = sArr[1];
    if (end[0] === "0") {
      end = end.substring(1);
    }

    setAggDaily(
      "https://www.bibleref.com/" +
      chapter +
      "/" +
      start +
      "/" +
      chapter +
      "-" +
      start +
      "-" +
      end +
      ".html"
    );
    console.log('aggDaily ' + aggDaily);
  };

  const submitFormHandler = async (event, selectValue) => {
    setDisabled(true);
    console.log('Daily -> submitFormHandler event ' + event);
    console.log('Daily -> submitFormHandle  selectValue ' + selectValue);

    event.preventDefault();

    try {
      const restOperation = get({
        apiName: 'matt1820api',
        path: '/dailybread',
        options: {
          queryParams: {
            cat: selectValue,
            lang: lang['lang']
          }
        }
      });

      const { body } = await restOperation.response;
      const response = await body.json();

      console.log('POST call succeeded');
      console.log(response);
      setVerseContent(response.Verse.S);
      setVerseStart(response.VerseStart.S);
      setVerseEnd(response.VerseEnd.S);
      setHyphen("-");
      setBookName(response.Book.S);
      setDisabled(false);
      setLearnmorelabel("Learn more");
      setLinkDisabled(false);
      aggDailyVerse(
        response.Book.S,
        response.VerseStart.S,
        response.VerseEnd.S
      );
      setModalVisible(true);


    } catch (e) {
      console.log('POST call failed: ', JSON.parse(e.response.body));
    }


  };

  // 1 - forgiveness
  // 2 - peace / love
  // 3 - illness
  // 4 - hope / faith
  // 5 - who am i
  // 6 - prayer
  // 7 - anxiety
  // 8 - personal interaction

  return (
    <div className="daily">
      <main>
        <ContentLayout maxContentWidth={600}>
          <Grid
            gridDefinition={[
              { colspan: { default: 8, xxs: 4 } },
              { colspan: { default: 8, xxs: 4 } },
              { colspan: { default: 8, xxs: 4 } },
            ]}
          >
            <Button variant="normal">
              <Box>
                <img
                  src={pray}
                  alt="pray"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "6")}
                />
              </Box>
              {t("dailyBreadScreen.prayerTitle")}
            </Button>
            <Button>
              <Box>
                <img
                  src={people}
                  alt="people"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "8")}
                />
              </Box>
              {t("dailyBreadScreen.personalinteractionTitle")}
            </Button>
            <Button>
              <Box>
                <img
                  src={hope}
                  alt="hope"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "4")}
                />
              </Box>
              {t("dailyBreadScreen.hopeTitle")}
            </Button>
          </Grid>
          <Grid
            gridDefinition={[
              { colspan: { default: 8, xxs: 4 } },
              { colspan: { default: 8, xxs: 4 } },
              { colspan: { default: 8, xxs: 4 } },
            ]}
          >
            <Button>
              <Box>
                <img
                  src={peace}
                  alt="peace"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "2")}
                />
              </Box>
              {t("dailyBreadScreen.peaceTitle")}
            </Button>
            <Button variant="normal">
              <Box>
                <img
                  src={whoami}
                  alt="whoami"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "5")}
                />
              </Box>
              {t("dailyBreadScreen.whoamiTitle")}
            </Button>
            <Button>
              <Box>
                <img
                  src={anxiety}
                  alt="anxiety"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "7")}
                />
              </Box>
              {t("dailyBreadScreen.anxietyTitle")}
            </Button>
          </Grid>

       
          <Grid
            gridDefinition={[
              { colspan: { default: 8, xxs: 4 } },
              { colspan: { default: 8, xxs: 4 } },
            ]}
          >
            <Button>
              <Box>
                <img
                  src={forgiveness}
                  alt="forgiveness"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "1")}
                />
              </Box>
              {t("dailyBreadScreen.foregivenessTitle")}
            </Button>
            <Button>
              <Box>
                <img
                  src={illness}
                  alt="illness"
                  width="100%"
                  height="100%"
                  onClick={(event) => submitFormHandler(event, "3")}
                />
              </Box>
              {t("dailyBreadScreen.illnessTitle")}
            </Button>
          </Grid>
          <Grid gridDefinition={[{ colspan: { default: 12, xxs: 6 } }]}>
            <Modal
              onDismiss={() => setModalVisible(false)}
              visible={modalVisible}
              footer="Peace be with you"
              header="Daily Bread"
            >
              <span>
                <br />

                <Header variant="h4">
                  <strong style={{ color: "darkgrey" }}>
                    {bookName} {verseStart} {hyphen} {verseEnd}
                  </strong>
                </Header>
                <br />

                <Header variant="h3">{verseContent}</Header>
                <SpaceBetween direction="vertical" size="s">
                  <Link
                    external
                    variant="primary"
                    externalIconAriaLabel=""
                    href={aggDaily}
                    disabled={linkDisabled}
                  >
                    {learnmorelabel}
                  </Link>
                </SpaceBetween>
              </span>
            </Modal>
          </Grid>
          <Grid gridDefinition={[{ colspan: { default: 10, xxs: 5 } }]}>
            <Form
              actions={
                <Button onClick={(event) => navigateHome(event)} variant="link">
                  {t("menuScreen.menuhome")}
                </Button>
              }
            ></Form>
          </Grid>
        </ContentLayout>
      </main>
    </div>
  );
}

const SideHelp = (lang) => {
  const { t, i18n } = useTranslation();
   useEffect(() => {
     console.log("Daily -> SideHelp -> lang " + lang);
     i18n.changeLanguage(lang["lang"]);
   }, [i18n]);



  return (
    <HelpPanel header={<h2> {t('menuScreen.menudailybread')}</h2>}>
      <ContentLayout>
        <Container
          header={
            <Header
              variant="h4"
              description=""
            >
              {t('dailyBreadScreen.help1')}
            </Header>
          }
        >
          <SpaceBetween size="m">
            <hr />
            <Box>
              {t('dailyBreadScreen.help2')}
            </Box>
            <hr />
            <Box>
              <strong>
                {t('dailyBreadScreen.help3')}
              </strong>
              <br />
            </Box>
            <hr />
            <Box>

              {t('dailyBreadScreen.help4')}
              <br />
            </Box>
          </SpaceBetween>
          <hr />
          <Box>
            <Header variant="h3"></Header>
            <ul></ul>
          </Box>
        </Container>
      </ContentLayout>
    </HelpPanel>
  );
}

function DailyBread() {

  console.log("DailyBread -> START");
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  console.log("Home -> lang " + lang);
  const [lnavopen, setLnavopen] = useState(false);


  return (
    <AppLayout
      disableContentPaddings={false}
      navigation={<Navigation lang={lang} />}
      content={<Content lang={lang} />}
      contentType="default"
      tools={<SideHelp lang={lang} />}
      navigationOpen={lnavopen}
      onNavigationChange={({ detail }) => setLnavopen(detail.open)}

    />
  );
}

export default DailyBread;
