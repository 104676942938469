import React, { useState, useEffect } from "react";
import "./index.css";

import {
  AppLayout,
  Box,
  Header,
  HelpPanel,
  Button,
  Container,
  Link,
  ColumnLayout,
  SpaceBetween,
  ContentLayout,
} from "@cloudscape-design/components";
import Navigation from "../../Navigation";
import "../locales/index";
import { useTranslation } from "react-i18next";
import jcImage from "./faceofjesus.gif";
import { I18n } from "aws-amplify/utils";
import { Amplify } from "aws-amplify";
import outputs from "../../aws-exports.js";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate } from "react-router-dom";
import { useContainerQuery } from "@cloudscape-design/component-toolkit";



Amplify.configure(outputs);

const Content = (lang) => {
  console.log("Home -> Content " + JSON.stringify(lang));

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const { t, i18n } = useTranslation();
  const [useMobileView, ref] = useContainerQuery(
    (entry) => entry.contentBoxWidth < 688
  );

  


  return (
    <div>
      <ColumnLayout columns={1}>
        <ContentLayout defaultPadding>
          <SpaceBetween direction="vertical" size="xxs">
            <Box variant="h4">{t("homeScreen.enterText")}</Box>
          </SpaceBetween>
          <div ref={ref}>
            <Container
              media={{
                content: <img src={jcImage} alt="jcplaceholder" />,
                position: "top",
                height: "33%",
              }}
            >
              <SpaceBetween direction="vertical" size="s">
                <Box variant="small">
                  My child, I have known you since before you were formed, and I
                  see both your struggles and your desire to understand truth.
                </Box>
              </SpaceBetween>
            </Container>
          </div>
        </ContentLayout>
      </ColumnLayout>
    </div>
  );
};

const SideHelp = (lang) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    console.log("Home -> SideHelp -> lang " + lang["lang"]);
    i18n.changeLanguage(lang["lang"]);
  }, [i18n]);


  return (
    <div>
      <HelpPanel header={<h2> {t("homeScreen.help0")}</h2>}>
        <ContentLayout>
          <Container
            header={
              <Header variant="h4" description="">
                {t("homeScreen.help1")}
              </Header>
            }
          >
            <SpaceBetween size="m">
              <hr />
              <Box>
                {t("homeScreen.help2")}
                <br />
              </Box>
              <hr />
              <Box>
                {t("homeScreen.help3")}
                <br />
              </Box>
            </SpaceBetween>
            <hr />
            <Box>{t("homeScreen.help4")}</Box>
          </Container>
        </ContentLayout>
      </HelpPanel>
    </div>
  );
};

function Home() {
  console.log("Home -> START");
  const [lnavopen, setLnavopen] = useState(true);
  const lang = localStorage.getItem("lang");
  console.log("Home -> lang " + lang);

  return (
    <div>
      <AppLayout
        disableContentPaddings={false}
        navigation={<Navigation lang={lang} />}
        content={<Content lang={lang} />}
        contentType="default"
        tools={<SideHelp lang={lang} />}
        navigationOpen={lnavopen}
        onNavigationChange={({ detail }) => setLnavopen(detail.open)}
      />
    </div>
  );
}
export default Home;
