import React, { useState, useEffect } from "react";
import "./index.css";

import {
  signUp,
  signIn,
  signOut,
  confirmSignUp,
  autoSignIn,
  getCurrentUser,
} from "aws-amplify/auth";
import {
  ContentLayout,
  SpaceBetween,
  Container,
  Button,
  Header,
  Link,
  Grid,
  Box,
  TextContent,
  ColumnLayout,
} from "@cloudscape-design/components";
import "../locales/index";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { I18n } from "aws-amplify/utils";
import pt from "./pt.svg";
import us from "./us.svg";
import ru from "./ru.svg";
import mx from "./mx.svg";

function Start() {
  console.log("Start -> Start");
  const [cognitoUser, setCognitoUser] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const navigateHome = (event, lang) => {
    console.log("Start -> navigateHome " + lang);
    localStorage.setItem("lang", lang);
    navigate("/Home");
  };



  return (
    <div>
      <ColumnLayout columns={4}>
        <div>
          <ContentLayout defaultPadding>
            <Container
              media={{
                content: (
                  <img
                    onClick={(event) => navigateHome(event, "pt")}
                    src={pt}
                    alt="pt"
                  />
                ),
              }}
            >
              <Box variant="h4">Portuguesa</Box>
            </Container>
          </ContentLayout>
        </div>
        <div>
          <ContentLayout defaultPadding>
            <Container
              media={{
                content: (
                  <img
                    onClick={(event) => navigateHome(event, "en")}
                    src={us}
                    alt="us"
                  />
                ),
              }}
            >
              <Box variant="h4">English </Box>
            </Container>
          </ContentLayout>
        </div>
        <div>
          <ContentLayout defaultPadding>
            <Container
              media={{
                content: (
                  <img
                    onClick={(event) => navigateHome(event, "ru")}
                    src={ru}
                    alt="ru"
                  />
                ),
              }}
            >
              <Box variant="h4">Русский</Box>
            </Container>
          </ContentLayout>
        </div>
        <div>
          <ContentLayout defaultPadding>
            <Container
              media={{
                content: (
                  <img
                    onClick={(event) => navigateHome(event, "es-MX")}
                    src={mx}
                    alt="mx"
                  />
                ),
              }}
            >
              <Box variant="h4">Español</Box>
            </Container>
          </ContentLayout>
         
        </div>
      </ColumnLayout>
    </div>
  );
}

export default Start;
