/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "matt1820api",
            "endpoint": "https://xib8e67n7e.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "matt1820DailyBread",
            "region": "us-east-1"
        },
        {
            "tableName": "matt1820Users-main",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
