import { SideNavigation, SpaceBetween } from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import "./pages/locales/index";
import { useTranslation } from "react-i18next";
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";

const Navigation = (lang) => {
  console.log("In Navigation and lang is " + JSON.stringify(lang));
  const { t, i18n } = useTranslation();
  const [NavigationItems, setNavigationItems] = useState([]);
  //const [language, setLanguage] = React.useState("");

  useEffect( () => {
    try {
      //const attributes = await fetchUserAttributes();
      console.log("attributes", lang["lang"]);
      //i18n.changeLanguage(attributes.locale);
      i18n.changeLanguage(lang["lang"]);
    } catch (error) {
      console.log("Error fetching user:", error);
    }

    let navigation_items = [];

    navigation_items.push({
      type: "section",

      text: t("homeScreen.matt1820"),
      expanded: true,
      items: [{ type: "link", text: t("menuScreen.menuhome"), href: "/Home" }],
    });
    navigation_items.push({
      type: "section",

      text: t("menuScreen.menuhisway"),
      expanded: true,
      items: [ { type: "link", text: t("menuScreen.menudailybread"), href: "/Daily" }]
    });
     navigation_items.push({
       type: "section",

       text: t("menuScreen.menutheirway"),
       expanded: true,
       items: [
         { type: "link", text: t("menuScreen.menuprayer"), href: "/Prayer" },
       ],
     });
     navigation_items.push({
       type: "section",

       text: t("menuScreen.menuourway"),
       expanded: true,
       items: [
         
       ],
     });

    setNavigationItems(navigation_items);
  }, [i18n]);

  return (
    <SpaceBetween>
      <SideNavigation activeHref={0} items={NavigationItems} />
    </SpaceBetween>
  );
};

export default Navigation;
